import { toggleHamburger } from './toggleHamburger';
import { mvSlider } from './sliders';
import { accordion } from './accordion';
import { tabMenu } from './tabMenu';
import { setUpPageTopButton } from './setUpPageTopButton';
toggleHamburger('js-hamburger', 'js-spmenu', 1024);
accordion();
tabMenu();
setUpPageTopButton('.js-pagetop');

document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault(); // URL更新を防ぐ

    // ハッシュを取得し、対応する要素を特定
    const hash = this.getAttribute('href');
    const target = document.querySelector(hash);

    // スムーズスクロールを実行
    if (target) {
      const offsetTop =
        target.getBoundingClientRect().top + window.pageYOffset - 100;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  });
});
