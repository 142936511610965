export const setUpPageTopButton = (elem) => {
  const pageTopButton = document.querySelector(elem);
  if (!pageTopButton) return;

  const setPageTopButtonOpacity = () => {
    if (document.documentElement.scrollTop === 0) {
      pageTopButton.style.opacity = 0;
      return;
    } else {
      pageTopButton.style.opacity = 1;
    }
  };

  window.addEventListener('DOMContentLoaded', function () {
    setPageTopButtonOpacity();
  });

  let scrollTimer = null;
  window.addEventListener('scroll', function () {
    pageTopButton.style.opacity = 0;
    clearTimeout(scrollTimer);

    scrollTimer = setTimeout(function () {
      setPageTopButtonOpacity();
    }, 500);
  });
};
